// @flow
import type { ToolID } from 'hooks/usePreferences/allTools';
import type { vec3 } from '@kitware/vtk.js/Common/Core/Math';
import type { CategoryLabel } from 'domains/viewer/AnnotationsManager';
import { env } from 'config/env';

export type NonPixelViewportTypes = 'PDF';
export const PDF_VIEWPORT_TYPE = 'PDF';

export type DreMprViewportTypes = 'AXIAL_DRE' | 'CORONAL_DRE' | 'SAGITTAL_DRE';
export const DRE_MPR_VIEWPORT_TYPES = ['AXIAL_DRE', 'CORONAL_DRE', 'SAGITTAL_DRE'];

export type Dre2DViewportTypes = 'TWO_D_DRE';
export const DRE_2D_VIEWPORT_TYPES = ['TWO_D_DRE'];

export type DreAll2DViewportTypes = Dre2DViewportTypes | DreMprViewportTypes;
export const DRE_ALL_2D_VIEWPORT_TYPES: DreAll2DViewportTypes[] = [
  ...DRE_2D_VIEWPORT_TYPES,
  ...DRE_MPR_VIEWPORT_TYPES,
];

export type Dre3DViewportTypes = 'THREE_D_DRE' | 'THREE_D_MIP';
export const DRE_3D_VIEWPORT_TYPES: Dre3DViewportTypes[] = ['THREE_D_DRE', 'THREE_D_MIP'];

export type DreAllViewportTypes = DreAll2DViewportTypes | Dre3DViewportTypes;
export const DRE_ALL_VIEWPORT_TYPES: DreAllViewportTypes[] = [
  ...DRE_2D_VIEWPORT_TYPES,
  ...DRE_MPR_VIEWPORT_TYPES,
  ...DRE_3D_VIEWPORT_TYPES,
];

export type ViewportTypeKeys = DreAllViewportTypes | NonPixelViewportTypes;
export type ViewportType = { displayName: string, value: string | number };
export type ViewportTypeValues = {
  [key: ViewportTypeKeys]: ViewportType,
};

export const ViewportTypes: ViewportTypeValues = {
  PDF: { displayName: 'PDF', value: 'PDF' },
  TWO_D_DRE: { displayName: '2D', value: 'TWO_D_DRE' },
  AXIAL_DRE: { displayName: 'Ax', value: 'AXIAL_DRE' },
  CORONAL_DRE: { displayName: 'Cor', value: 'CORONAL_DRE' },
  SAGITTAL_DRE: { displayName: 'Sag', value: 'SAGITTAL_DRE' },
  THREE_D_DRE: { displayName: 'Vol', value: 'THREE_D_DRE' },
  THREE_D_MIP: { displayName: 'MIP', value: 'THREE_D_MIP' },
  // Fusion: { displayName: 'FUSION', value: 'FUSION' },
  // TODO(vamshi): re-enable once MIP functionality is ready (EN-1717)
  // FlyThrough: { displayName: 'Fly', value: 'flythrough' },
};

export const thumbnailSize = {
  width: 65,
  height: 65,
};

export const MODALITIES = [
  { id: 1, name: 'CR', value: 'CR' },
  { id: 2, name: 'CT', value: 'CT' },
  { id: 3, name: 'DX', value: 'DX' },
  { id: 4, name: 'MG', value: 'MG' },
  { id: 5, name: 'MR', value: 'MR' },
  { id: 6, name: 'PT', value: 'PT' },
  { id: 7, name: 'RF', value: 'RF' },
  { id: 8, name: 'XA', value: 'XA' },
  { id: 9, name: 'US', value: 'US' },
];

export const NOOP = () => {};

export function IdentityFn<T>(v: T): T {
  return v;
}

export const AnnotationTypes = {
  LENGTH: 'LENGTH',
  ANGLE: 'ANGLE',
  COBB_ANGLE: 'COBB_ANGLE',
  ELLIPSE: 'ELLIPSE',
  RECT: 'RECT',
  ARROW: 'ARROW',
  VERTEBRAE_LABEL: 'VERTEBRAE_LABEL',
  LANDMARK: 'LANDMARK',
  CONTOUR: 'CONTOUR',
  CIRCLE: 'CIRCLE',
  SEG_MASK_LABEL: 'SEG_MASK_LABEL',
  CUSTOM: 'CUSTOM',
  TEXT: 'TEXT',
};

export const AnnotationTypesAliases = {
  RECTANGLE: AnnotationTypes.RECT,
};

export const ANNOTATION_PADDING = 10;

export const CSV_FILENAME = 'worklist.csv';

export const SORT_ORDER = Object.freeze({
  ASCENDING: 'ASC',
  DESCENDING: 'DESC',
});

export const IS_TEST_ENV: boolean =
  env.NODE_ENV === 'test' ||
  env.STORYBOOK_STORYSHOTS === 'true' ||
  env.STORYBOOK === 'true' ||
  env.E2E_TEST === 'true' ||
  env.TEST === 'true';

export const MY_QUEUE: 'MY_QUEUE' = 'MY_QUEUE';
export const PENDING: 'PENDING' = 'PENDING';
export const IN_PROGRESS: 'IN_PROGRESS' = 'IN_PROGRESS';
export const RECENTLY_READ: 'RECENTLY_READ' = 'RECENTLY_READ';
export const ARCHIVE: 'ARCHIVE' = 'ARCHIVE';
export const WORKLIST_VIEW: 'WORKLIST_VIEW' = 'WORKLIST_VIEW';

export type WorklistTab =
  | typeof MY_QUEUE
  | typeof PENDING
  | typeof IN_PROGRESS
  | typeof RECENTLY_READ
  | typeof ARCHIVE
  | typeof WORKLIST_VIEW;

export const WORKLIST_TABS: Array<WorklistTab> = [
  MY_QUEUE,
  PENDING,
  IN_PROGRESS,
  RECENTLY_READ,
  ARCHIVE,
  WORKLIST_VIEW,
];

export const CAN_CLAIM_LOCKED_CASE_SURFACES: Array<WorklistTab> = [IN_PROGRESS, WORKLIST_VIEW];

export const DEFAULT_WORKLIST_SORT_COLUMN_ID = 'studyDate';

export const EXAM: 'exam' = 'exam';
export const HISTORY: 'history' = 'history';
export const COMPARISON: 'comparison' = 'comparison';
export const TECHNIQUE: 'technique' = 'technique';
export const FINDINGS: 'findings' = 'findings';
export const IMPRESSIONS: 'impressions' = 'impressions';

export type ReportField =
  | typeof EXAM
  | typeof HISTORY
  | typeof COMPARISON
  | typeof TECHNIQUE
  | typeof FINDINGS
  | typeof IMPRESSIONS;
export const REPORT_FIELDS: ReportField[] = [
  'exam',
  'history',
  'comparison',
  'technique',
  'findings',
  'impressions',
];

export const DEFAULT_SHOW_PICKLIST_OPTIONS_IN_EDITOR: boolean = false;

export const DEFAULT_AUTO_FILL_COMPARISON_ENABLED: boolean = false;

export const EMPTY: { OBJECT: {}, ARRAY: $FlowFixMe[] } = {
  OBJECT: Object.freeze({}),
  ARRAY: Object.freeze([]),
};

export const REPORTER_CONTEXT_MENU_ID = 'REPORTER_CONTEXT_MENU_ID';

export const VERTEBRAE_LABELS = Object.freeze({
  C1: { label: 'C1', value: 'RID6142' },
  C2: { label: 'C2', value: 'RID6143' },
  C3: { label: 'C3', value: 'RID6144' },
  C4: { label: 'C4', value: 'RID6145' },
  C5: { label: 'C5', value: 'RID6146' },
  C6: { label: 'C6', value: 'RID6147' },
  C7: { label: 'C7', value: 'RID6148' },
  T1: { label: 'T1', value: 'RID6151' },
  T2: { label: 'T2', value: 'RID6152' },
  T3: { label: 'T3', value: 'RID6153' },
  T4: { label: 'T4', value: 'RID6154' },
  T5: { label: 'T5', value: 'RID6155' },
  T6: { label: 'T6', value: 'RID6156' },
  T7: { label: 'T7', value: 'RID6157' },
  T8: { label: 'T8', value: 'RID6158' },
  T9: { label: 'T9', value: 'RID6159' },
  T10: { label: 'T10', value: 'RID6160' },
  T11: { label: 'T11', value: 'RID6161' },
  T12: { label: 'T12', value: 'RID6162' },
  L1: { label: 'L1', value: 'RID6164' },
  L2: { label: 'L2', value: 'RID6165' },
  L3: { label: 'L3', value: 'RID6166' },
  L4: { label: 'L4', value: 'RID6167' },
  L5: { label: 'L5', value: 'RID6168' },
  S1: { label: 'S1', value: 'S1' },
});

export const DISC_LABELS = Object.freeze({
  'C1-C2': { label: 'C1-C2', value: 'RID6181' },
  'C2-C3': { label: 'C2-C3', value: 'RID6182' },
  'C3-C4': { label: 'C3-C4', value: 'RID6183' },
  'C4-C5': { label: 'C4-C5', value: 'RID6184' },
  'C5-C6': { label: 'C5-C6', value: 'RID6185' },
  'C6-C7': { label: 'C6-C7', value: 'RID6186' },
  'C7-T1': { label: 'C7-T1', value: 'RID6187' },
  'T1-T2': { label: 'T1-T2', value: 'RID6189' },
  'T2-T3': { label: 'T2-T3', value: 'RID6190' },
  'T3-T4': { label: 'T3-T4', value: 'RID6191' },
  'T4-T5': { label: 'T4-T5', value: 'RID6192' },
  'T5-T6': { label: 'T5-T6', value: 'RID6193' },
  'T6-T7': { label: 'T6-T7', value: 'RID6194' },
  'T7-T8': { label: 'T7-T8', value: 'RID6195' },
  'T8-T9': { label: 'T8-T9', value: 'RID6196' },
  'T9-T10': { label: 'T9-T10', value: 'RID6197' },
  'T10-T11': { label: 'T10-T11', value: 'RID6198' },
  'T11-T12': { label: 'T11-T12', value: 'RID6199' },
  'T12-L1': { label: 'T12-L1', value: 'RID6200' },
  'L1-L2': { label: 'L1-L2', value: 'RID6202' },
  'L2-L3': { label: 'L2-L3', value: 'RID6203' },
  'L3-L4': { label: 'L3-L4', value: 'RID6204' },
  'L4-L5': { label: 'L4-L5', value: 'RID6205' },
  'L5-S1': { label: 'L5-S1', value: 'RID6206' },
});

// $FlowFixMe[incompatible-type]
export const vertebraeLabels: Array<CategoryLabel> = Object.values(VERTEBRAE_LABELS);
// $FlowFixMe[incompatible-type]
export const discLabels: Array<CategoryLabel> = Object.values(DISC_LABELS);

export const facetLabels = [
  { label: 'C1-C2_left', value: 'RID6181', isFacet: true, facetType: 'left' },
  { label: 'C1-C2_right', value: 'RID6181', isFacet: true, facetType: 'right' },
  { label: 'C2-C3_left', value: 'RID6182', isFacet: true, facetType: 'left' },
  { label: 'C2-C3_right', value: 'RID6182', isFacet: true, facetType: 'right' },
  { label: 'C3-C4_left', value: 'RID6183', isFacet: true, facetType: 'left' },
  { label: 'C3-C4_right', value: 'RID6183', isFacet: true, facetType: 'right' },
  { label: 'C4-C5_left', value: 'RID6184', isFacet: true, facetType: 'left' },
  { label: 'C4-C5_right', value: 'RID6184', isFacet: true, facetType: 'right' },
  { label: 'C5-C6_left', value: 'RID6185', isFacet: true, facetType: 'left' },
  { label: 'C5-C6_right', value: 'RID6185', isFacet: true, facetType: 'right' },
  { label: 'C6-C7_left', value: 'RID6186', isFacet: true, facetType: 'left' },
  { label: 'C6-C7_right', value: 'RID6186', isFacet: true, facetType: 'right' },
  { label: 'C7-T1_left', value: 'RID6187', isFacet: true, facetType: 'left' },
  { label: 'C7-T1_right', value: 'RID6187', isFacet: true, facetType: 'right' },
  { label: 'T1-T2_left', value: 'RID6189', isFacet: true, facetType: 'left' },
  { label: 'T1-T2_right', value: 'RID6189', isFacet: true, facetType: 'right' },
  { label: 'T2-T3_left', value: 'RID6190', isFacet: true, facetType: 'left' },
  { label: 'T2-T3_right', value: 'RID6190', isFacet: true, facetType: 'right' },
  { label: 'T3-T4_left', value: 'RID6191', isFacet: true, facetType: 'left' },
  { label: 'T3-T4_right', value: 'RID6191', isFacet: true, facetType: 'right' },
  { label: 'T4-T5_left', value: 'RID6192', isFacet: true, facetType: 'left' },
  { label: 'T4-T5_right', value: 'RID6192', isFacet: true, facetType: 'right' },
  { label: 'T5-T6_left', value: 'RID6193', isFacet: true, facetType: 'left' },
  { label: 'T5-T6_right', value: 'RID6193', isFacet: true, facetType: 'right' },
  { label: 'T6-T7_left', value: 'RID6194', isFacet: true, facetType: 'left' },
  { label: 'T6-T7_right', value: 'RID6194', isFacet: true, facetType: 'right' },
  { label: 'T7-T8_left', value: 'RID6195', isFacet: true, facetType: 'left' },
  { label: 'T7-T8_right', value: 'RID6195', isFacet: true, facetType: 'right' },
  { label: 'T8-T9_left', value: 'RID6196', isFacet: true, facetType: 'left' },
  { label: 'T8-T9_right', value: 'RID6196', isFacet: true, facetType: 'right' },
  { label: 'T9-T10_left', value: 'RID6197', isFacet: true, facetType: 'left' },
  { label: 'T9-T10_right', value: 'RID6197', isFacet: true, facetType: 'right' },
  { label: 'T10-T11_left', value: 'RID6198', isFacet: true, facetType: 'left' },
  { label: 'T10-T11_right', value: 'RID6198', isFacet: true, facetType: 'right' },
  { label: 'T11-T12_left', value: 'RID6199', isFacet: true, facetType: 'left' },
  { label: 'T11-T12_right', value: 'RID6199', isFacet: true, facetType: 'right' },
  { label: 'T12-L1_left', value: 'RID6200', isFacet: true, facetType: 'left' },
  { label: 'T12-L1_right', value: 'RID6200', isFacet: true, facetType: 'right' },
  { label: 'L1-L2_left', value: 'RID6202', isFacet: true, facetType: 'left' },
  { label: 'L1-L2_right', value: 'RID6202', isFacet: true, facetType: 'right' },
  { label: 'L2-L3_left', value: 'RID6203', isFacet: true, facetType: 'left' },
  { label: 'L2-L3_right', value: 'RID6203', isFacet: true, facetType: 'right' },
  { label: 'L3-L4_left', value: 'RID6204', isFacet: true, facetType: 'left' },
  { label: 'L3-L4_right', value: 'RID6204', isFacet: true, facetType: 'right' },
  { label: 'L4-L5_left', value: 'RID6205', isFacet: true, facetType: 'left' },
  { label: 'L4-L5_right', value: 'RID6205', isFacet: true, facetType: 'right' },
  { label: 'L5-S1_left', value: 'RID6206', isFacet: true, facetType: 'left' },
  { label: 'L5-S1_right', value: 'RID6206', isFacet: true, facetType: 'right' },
];

export const miscSpineLabels = [
  { label: 'Spinal canal', value: 'RID6135' },
  { label: 'Transitional vertebra', value: 'SRNAID1' },
  { label: 'Conus', value: 'conus', isFacet: true, facetType: 'right' },
  // Foramen is being treated as a facet so that it doesn't show up on all
  // slices
  { label: 'Foramen', value: 'RID6181', isFacet: true, facetType: 'right' },
];

export const miscKnee = [
  // These labels is being treated as a facet so that it doesn't show up on all
  // slices
  { label: 'MS', value: 'Median sulcus', isFacet: true, facetType: 'right' },
  { label: 'SFC', value: 'Superior femoral condylar cartilage', isFacet: true, facetType: 'right' },
  { label: 'IT', value: 'Inferior trochlea', isFacet: true, facetType: 'right' },
  { label: 'IN', value: 'Intercondylar notch', isFacet: true, facetType: 'right' },
];

export const shoulderLabels = [
  { label: 'AC Joint', value: 'AC Joint' },
  { label: 'Acromion', value: 'Acromion' },
  { label: 'SA-SD Bursa', value: 'SA-SD Bursa' },
  { label: 'IP', value: 'IP' },
  { label: 'TM', value: 'TM' },
  { label: 'SB', value: 'SB' },
  { label: 'Rotator Interval', value: 'Rotator Interval' },
  { label: 'LHBT-intra', value: 'LHBT-intra' },
  { label: 'LHBT-extra', value: 'LHBT-extra' },
  { label: 'Glenohumeral Joint', value: 'Glenohumeral Joint' },
  { label: 'Labrum', value: 'Labrum' },
  { label: 'Labrum Tear', value: 'Labrum Tear' },
  { label: 'Labrum Degeneration', value: 'Labrum Degeneration' },
];
// these labels will show up for landmark tool
// edit this variable to add or remove landmark labels
// because it concatenates other labels it is suggested to edit the child variable instead of this variable directly
export const landmarkLabels:
  | typeof discLabels
  | typeof vertebraeLabels
  | typeof facetLabels
  | typeof miscSpineLabels
  | typeof miscKnee = discLabels.concat(vertebraeLabels, facetLabels, miscSpineLabels, miscKnee);

export const rectLabels = shoulderLabels;

// these labels will show up for contour tool
// edit this variable to add or remove contour labels
export const contourLabels = [
  // Spine contour labels
  VERTEBRAE_LABELS.C1,
  VERTEBRAE_LABELS.C2,
  DISC_LABELS['C2-C3'],
  VERTEBRAE_LABELS.C3,
  DISC_LABELS['C3-C4'],
  VERTEBRAE_LABELS.C4,
  DISC_LABELS['C4-C5'],
  VERTEBRAE_LABELS.C5,
  DISC_LABELS['C5-C6'],
  VERTEBRAE_LABELS.C6,
  DISC_LABELS['C6-C7'],
  VERTEBRAE_LABELS.C7,
  DISC_LABELS['C7-T1'],
  VERTEBRAE_LABELS.T1,
  DISC_LABELS['T1-T2'],
  VERTEBRAE_LABELS.T2,
  DISC_LABELS['T2-T3'],
  VERTEBRAE_LABELS.T3,
  DISC_LABELS['T3-T4'],
  VERTEBRAE_LABELS.T4,
  DISC_LABELS['T4-T5'],
  VERTEBRAE_LABELS.T5,
  DISC_LABELS['T5-T6'],
  VERTEBRAE_LABELS.T6,
  DISC_LABELS['T6-T7'],
  VERTEBRAE_LABELS.T7,
  DISC_LABELS['T7-T8'],
  VERTEBRAE_LABELS.T8,
  DISC_LABELS['T8-T9'],
  VERTEBRAE_LABELS.T9,
  DISC_LABELS['T9-T10'],
  VERTEBRAE_LABELS.T10,
  DISC_LABELS['T10-T11'],
  VERTEBRAE_LABELS.T11,
  DISC_LABELS['T11-T12'],
  VERTEBRAE_LABELS.T12,
  DISC_LABELS['T12-L1'],
  VERTEBRAE_LABELS.L1,
  DISC_LABELS['L1-L2'],
  VERTEBRAE_LABELS.L2,
  DISC_LABELS['L2-L3'],
  VERTEBRAE_LABELS.L3,
  DISC_LABELS['L3-L4'],
  VERTEBRAE_LABELS.L4,
  DISC_LABELS['L4-L5'],
  VERTEBRAE_LABELS.L5,
  DISC_LABELS['L5-S1'],
  VERTEBRAE_LABELS.S1,

  // Knee contour labels
  { label: 'AM', value: 'Anterior meniscus' },
  { label: 'PM', value: 'Posterior meniscus' },
  { label: 'MB', value: 'Meniscal body' },
  { label: 'PC', value: 'Patellar Cartilage' },
  { label: 'FCC', value: 'Femoral condyle cartilage' },
  { label: 'TC', value: 'Tibial cartilage' },
  { label: 'Femur', value: 'RID2662' },
  { label: 'Tibia', value: 'RID2885' },
  { label: 'Fibula', value: 'RID2871' },
  { label: 'Patella', value: 'RID2746' },
  { label: 'ACL', value: 'RID2781' },
  { label: 'PCL', value: 'RID2784' },
  // Shoulder contour labels
  { label: 'Scapula', value: 'RID1860' },
  { label: 'Glenoid', value: 'RID1864' },
  { label: 'CP', value: 'Coracoid process' },
  { label: 'Acromion', value: 'RID1862' },
  { label: 'Clavicle', value: 'Clavicle' },
  { label: 'Humerus', value: 'Humerus' },
  { label: 'GC', value: 'Glenoid cartilage' },
  { label: 'HC', value: 'Humeral cartilage' },
  { label: 'SupM', value: 'Supraspinatus muscle' },
  { label: 'SupTd', value: 'Supraspinatus tendon' },
  { label: 'IM', value: 'Infraspinatus muscle' },
  { label: 'ITd', value: 'Infraspinatus tendon' },
  { label: 'TMM', value: 'Teres minor muscle' },
  { label: 'TMTd', value: 'Teres minor tendon' },
  { label: 'SubM', value: 'Subscapularis muscle' },
  { label: 'SubTd', value: 'Subscapularis tendon' },
  { label: 'EABTd', value: 'extra-articular long head of biceps tendon' },
  { label: 'IABTd', value: 'intra-articular long head of biceps tendon' },
  { label: 'AL', value: 'Anterior Labrum' },
  { label: 'PL', value: 'Posterior Labrum' },
];

export const elementClasses = {
  observation: { displayName: 'Observation', color: '#FFFF66' },
  anatomicLocation: { displayName: 'Anatomic Location', color: '#FD5B78' },
  relation: { displayName: 'Relation', color: '#66FF66' },
  observationModifier: { displayName: 'Observation modifier', color: '#50BFE6' },
  anatomicModifier: { displayName: 'Anatomic modifier', color: '#FF6EFF' },
  uncertainty: { displayName: 'Uncertainty', color: '#FF3399' },
  locationOnImage: { displayName: 'Location on image', color: '#6CDAE7' },
};

export const MAX_TOOL_SELECTED = 8;
export const MAX_TOOL_WHEEL_SELECTED = 10;

const REPORTER_BASE_PATH = '/case/:worklistId/reporter';

export const PATH = {
  HOME: '/home',
  LOGIN: '/login',
  LOGOUT: '/logout',
  ADMIN: '/admin',
  CONFIRM_ACCOUNT: '/user/:userId/confirm-user-account',
  RESET_PASSWORD: '/user/reset-password',
  COMPLETE_RESET_PASSWORD: '/user/:userId/reset-password',
  WORKLIST: '/worklist',
  PATIENT_JACKET: '/worklist/jacket/:smid',
  VIEWER: '/case/:worklistId/viewer/:windowId',
  REPORTER: REPORTER_BASE_PATH,
  REPORTER_MACRO_NEW: `${REPORTER_BASE_PATH}/macro/new`,
  REPORTER_MACRO_CLONE: `${REPORTER_BASE_PATH}/macro/:macroId/clone`,
  REPORTER_MACRO_EDIT: `${REPORTER_BASE_PATH}/macro/:macroId/edit`,
  REPORTER_TEMPLATE_NEW: `${REPORTER_BASE_PATH}/template/new`,
  REPORTER_TEMPLATE_CLONE: `${REPORTER_BASE_PATH}/template/:templateId/clone`,
  REPORTER_TEMPLATE_EDIT: `${REPORTER_BASE_PATH}/template/:templateId/edit`,
  URT: '/case/:worklistId/urt',
  ACCOUNT: '/account',
  PRODUCTPORTAL: '/productportal',
  EXTERNAL: '/go',
  VERIFY_MFA: '/verify',
  PIXEL_WORKER: '/pixel-worker',
  DEBUG: '/_debug',
};

export const REDIRECT_PATH = {
  VIEWER: '/viewer/:windowId/worklists/:worklistId',
  REPORTER: '/reporter/:worklistId',
};

export const EMAILS = {
  CONTACT: 'contact@sironamedical.com',
  SUPPORT: 'support@sironamedical.com',
  ITSUPPORT: 'itsupport@sironamedical.com',
};

export const AUTHLESS_PATHS = [
  PATH.LOGIN,
  PATH.CONFIRM_ACCOUNT,
  PATH.RESET_PASSWORD,
  PATH.COMPLETE_RESET_PASSWORD,
  PATH.VERIFY_MFA,
];

export const DRAGGABLE_TYPES = {
  TOOL: 'tool',
  TOOLBAR: 'toolbar',
  MOUSE: 'mouse',
  WHEEL: 'wheel',
  LIST: 'list',
};

/*
orientation matrices based on the type, please refer to dicom tag imageOrientationPatient (0020,0037)
resources: https://nipy.org/nibabel/dicom/dicom_orientation.html
http://dicom.nema.org/medical/dicom/current/output/chtml/part03/sect_C.7.6.2.html
*/

type ImageOrientations = { [key: string]: number[] };
type DefaultCameraParameters = $ReadOnly<{ [key: string]: vec3 }>;
type SlicingIndexes = $ReadOnly<{ [key: string]: number }>;

export const IMAGE_ORIENTATIONS: ImageOrientations = {
  axial: [1, 0, 0, 0, 1, 0],
  sagittal: [0, 1, 0, 0, 0, 1],
  coronal: [1, 0, 0, 0, 0, 1],
};

export const IMAGE_NORMALS: DefaultCameraParameters = {
  TWO_D_DRE: [0, 0, 1],
  AXIAL_DRE: [0, 0, 1],
  CORONAL_DRE: [0, 1, 0],
  SAGITTAL_DRE: [-1, 0, 0],
  THREE_D_DRE: [0, 0, 1],
  THREE_D_MIP: [0, 0, 1],
};

export const IMAGE_VIEW_UPS: DefaultCameraParameters = {
  TWO_D_DRE: [0, -1, 0],
  AXIAL_DRE: [0, -1, 0],
  CORONAL_DRE: [0, 0, 1],
  SAGITTAL_DRE: [0, 0, 1],
  THREE_D_DRE: [0, -1, 0],
  THREE_D_MIP: [0, -1, 0],
};

export const SLICING_INDEX: SlicingIndexes = {
  TWO_D_DRE: 2,
  AXIAL_DRE: 2,
  CORONAL_DRE: 1,
  SAGITTAL_DRE: 0,
  THREE_D_DRE: 2,
  THREE_D_MIP: 2,
};

export const WORK_LIST_UPDATE = 'WORK_LIST_UPDATE';

export const CATEGORIZABLE_ANNOTATIONS = [
  AnnotationTypes.CONTOUR,
  AnnotationTypes.VERTEBRAE_LABEL,
  AnnotationTypes.LANDMARK,
  AnnotationTypes.RECT,
  AnnotationTypes.SEG_MASK_LABEL,
];

export const MULTI_SERIES_ANNOTATIONS = [
  AnnotationTypes.VERTEBRAE_LABEL,
  AnnotationTypes.SEG_MASK_LABEL,
];

export const IMMOVABLE_ANNOTATIONS = [AnnotationTypes.SEG_MASK_LABEL];

export type WorklistType = 'comparison' | 'default';
export const WorkListTypes = Object.freeze({
  COMPARISON: 'comparison',
  DEFAULT: 'default',
});

export const CASE_ACTIONS = {
  READ: 'READ',
  VIEW: 'VIEW',
};

export const PASSWORD_REQUIREMENT_TEXT = `Passwords should be at least 8 characters long and contain at least one number,
special character, uppercase letter, and lowercase letter.`;

const { BUILD_SHA1 = '', GIT_TAG = '' } = env;

export const APP_VERSION: string = GIT_TAG.startsWith('v')
  ? GIT_TAG.replace('v', '')
  : GIT_TAG || '';
export const APP_BUILD: string = BUILD_SHA1 || '';

export const CHROME_EXTENSION_INSTALL_PAGE =
  'https://chrome.google.com/webstore/detail/sirona-medical-workspace/eodalijocobjhbkcdaafdmmhcloanllp';

// Use this to set the allowed number of viewer windows
export const MAX_VIEWER_WINDOWS: number = 2;

export const VIEWER_WINDOWS: Array<string> = Array.from({ length: MAX_VIEWER_WINDOWS }, (_, i) =>
  String(i)
);

export type Layout = [number, number];
export const LAYOUTS: {
  [key: string]: Layout,
} = {
  LAYOUT_1x1: [1, 1],
  LAYOUT_1x2: [1, 2],
  LAYOUT_1x3: [1, 3],
  LAYOUT_1x4: [1, 4],
  LAYOUT_2x1: [2, 1],
  LAYOUT_2x2: [2, 2],
  LAYOUT_2x3: [2, 3],
  LAYOUT_2x4: [2, 4],
  LAYOUT_4x1: [4, 1],
  LAYOUT_3x3: [3, 3],
};

export const FN_KEYS: { [key: string]: string } = {
  '112': 'F1',
  '113': 'F2',
  '114': 'F3',
  '115': 'F4',
  '116': 'F5',
  '117': 'F6',
  '118': 'F7',
  '119': 'F8',
  '120': 'F9',
  '121': 'F10',
  '122': 'F11',
  '123': 'F12',
};

export const DENY_LIST_KEYCODES: string[] = [
  // https://developer.mozilla.org/en-US/docs/Web/API/KeyboardEvent/code/code_values
  'MediaTrackPrevious',
  'MediaTrackNext',
  'MediaPlayPause',
  'MediaStop',
  'MediaSelect',
  'KanaMode',
  'Lang1',
  'Lang2',
  'ContextMenu',
  'Help',
  'IntlRo',
  'IntlYen',
  'LaunchApp1',
  'LaunchApp2',
  'LaunchMail',
  'BrightnessUp',
  'BrightnessDown',
  'Eject',
  'LogOff',
  'Power',
  'PowerOff',
  'Hibernate',
  'StandBy',
  'WakeUp',

  // Firefox specific
  'VolumeDown',
  'VolumeUp',
  'VolumeMute',

  // Chrome specific from https://github.com/chromium/chromium/blob/154d64c3453e914b0e1083903dc70405715decf5/ui/events/keycodes/dom/dom_code_data.inc
  'AudioVolumeUp',
  'AudioVolumeDown',
  'AudioVolumeMute',

  'MediaPlay',
  'MediaPause',
  'MediaRecord',
  'MediaFastForward',
  'MediaRewind',

  'BrowserSearch',
  'BrowserHome',
  'BrowserBack',
  'BrowserForward',
  'BrowserStop',
  'BrowserRefresh',
  'BrowserFavorites',

  'LaunchControlPanel',
  'SelectTask',
  'LaunchScreenSaver',
  'LaunchAssistant',
  'ZoomToggle',
  'MailReply',
  'MailForward',
  'MailSend',
  'KeyboardLayoutSelect',
  'ShowAllWindows',
  'Sleep',
  'Lang3',
  'Lang4',
];

export const LOADING_SCREEN_TIMEOUT = 1500;

export const SORTABLE_WORK_LIST_COLUMN_MAP = {
  priority: 'PRIORITY',
  updated: 'UPDATED',
  date: 'DATE',
  modality: 'MODALITY',
  studyDescription: 'STUDY_DESCRIPTION',
  status: 'STATUS',
  accessionNumber: 'ACCESSION_NUMBER',
  mrn: 'MRN',
  site: 'SITE',
  referringPhysician: 'REFERRING_PHYSICIAN',
  patientName: 'PATIENT_NAME',
  patientSex: 'PATIENT_SEX',
  patientType: 'PATIENT_TYPE',
  patientDob: 'PATIENT_DOB',
  bodyPart: 'BODY_PART',
  studyDate: 'STUDY_DATETIME',
  submittedAt: 'SUBMITTED_AT',
  hasDraftReport: 'HAS_DRAFT_REPORT',
  customStatus: 'CUSTOM_STATUS_NAME',
};

export const SORTABLE_WORK_LIST_COLUMN_MAP_REVERSED: { [key: mixed]: string } = Object.fromEntries(
  Object.entries(SORTABLE_WORK_LIST_COLUMN_MAP).map(([key, id]) => [id, key])
);

export const TOOL_CURSORS: {
  [key: ToolID | 'CURSOR' | 'HANDLE' | 'NODE' | 'ZOOM_OUT' | 'ZOOM_IN']: {
    value: string,
    x?: number,
    y?: number,
  },
} = {
  CURSOR: {
    value: 'url(/images/cursor-normal.svg)',
  },
  HANDLE: {
    value: 'url(/images/cursor-handle.svg)',
  },
  NODE: {
    value: 'url(/images/cursor-node.svg)',
  },
  ZOOM: {
    value: 'url(/images/cursor-zoom-in.svg)',
    x: 8,
    y: 8,
  },
  ZOOM_IN: {
    value: 'url(/images/cursor-zoom-in.svg)',
    x: 8,
    y: 8,
  },
  ZOOM_OUT: {
    value: 'url(/images/cursor-zoom-out.svg)',
    x: 8,
    y: 8,
  },
  PAN: {
    value: 'url(/images/cursor-pan.svg)',
    x: 8,
    y: 4,
  },
  FAST_SCROLL: {
    value: 'url(/images/cursor-fast-scroll.svg)',
  },
  REGULAR_SCROLL: {
    value: 'url(/images/cursor-regular-scroll.svg)',
  },
  WWWC: {
    value: 'url(/images/cursor-wl.svg)',
  },
  RECTANGLE: {
    value: 'url(/images/cursor-rectangle.svg)',
    x: 5.5,
    y: 18.5,
  },
  LENGTH: {
    value: 'url(/images/cursor-length.svg)',
  },
  ELLIPSE: {
    value: 'url(/images/cursor-ellipse.svg)',
    x: 5.5,
    y: 18.5,
  },
  CIRCLE: {
    value: 'url(/images/cursor-circle.svg)',
    x: 11,
    y: 11,
  },
  MAGNIFY_GLASS: {
    value: 'url(/images/cursor-zoom.svg)',
  },
  ANGLE: {
    value: 'url(/images/cursor-angle.svg)',
  },
  COBB_ANGLE: {
    value: 'url(/images/cursor-cobb.svg)',
  },
  ROTATE: {
    value: 'url(/images/cursor-rotate.svg)',
  },
  FREE_ROTATE: {
    value: 'url(/images/cursor-free-rotate.svg)',
  },
  ERASER: {
    value: 'url(/images/cursor-erase.svg)',
    x: 11,
    y: 11,
  },
  ERASE_ALL: {
    value: 'url(/images/cursor-erase.svg)',
    x: 11,
    y: 11,
  },
  VERTEBRAE_LABEL: {
    value: 'url(/images/cursor-vertebrae.svg)',
  },
  CONTOUR: {
    value: 'url(/images/cursor-contour.svg)',
  },
  LANDMARK: {
    value: 'url(/images/cursor-landmark.svg)',
    x: 11,
    y: 11,
  },
  JUMP_TO: {
    value: 'url(/images/cursor-jumpto.svg)',
    x: 11,
    y: 11,
  },
  ARROW: {
    value: 'url(/images/cursor-arrow.svg)',
  },
  KEY_IMAGE: {
    value: 'url(/images/cursor-bookmark.svg)',
  },
  LUNG: {
    value: 'url(/images/cursor-lung.svg)',
  },
};

export const CURSOR_DISABLED = 'not-allowed';

export const SEARCH_DEBOUNCE_MS = 300;

export const DISCRIMINATORY_WORDS: Array<RegExp | string> = [
  'right',
  'left',
  'no',
  'abnormal',
  'normal',
  'new',
];
export const Int16 = {
  MAX_VALUE: (2 << 15) / 2 - 1,
  MIN_VALUE: -(2 << 15) / 2,
};

export const SRNA_PERSIST_TOKEN = '__srna_persist__';

export const NOT_FOUND_CASE_ID = 'undefined';

/*
 * default to a single frame being sufficient to mark it as reviewed
 * will be user configurable after https://sironamedical.atlassian.net/browse/VX-1254
 */
export const REVIEWED_THRESHOLD = 0;

/*
 * Necessary tags for PET SUV calculation for the corrected image.
 */
export const CORRECTED_IMAGE_TAGS = ['ATTN', 'DECY'];

export const PET_UNITS = {
  // counts ({counts}, UCUM, "Counts")
  CNTS: 'CNTS',
  // unitless (1, UCUM, "no units")
  NONE: 'NONE',

  // centimeter**2 (cm2, UCUM, "Centimeter**2")
  CM2: 'CM2',

  // centimeter**2/milliliter (cm2/ml, UCUM, "Centimeter**2/milliliter")

  CM2ML: 'CM2ML',

  // percent (%, UCUM, "Percent")
  PCNT: 'PCNT',

  // counts/second ({counts}/s, UCUM, "Counts per second")
  CPS: 'CPS',

  // Becquerels/milliliter (Bq/ml, UCUM, "Becquerels/milliliter")
  BQML: 'BQML',

  // milligram/minute/milliliter (mg/min/ml, UCUM, "Milligrams/minute/milliliter")
  MGMINML: 'MGMINML',

  // micromole/minute/milliliter (umol/min/ml, UCUM, "Micromole/minute/milliliter")
  UMOLMINML: 'UMOLMINML',

  // milliliter/minute/gram (ml/min/g, UCUM, "Milliliter/minute/gram")
  MLMING: 'MLMING',

  //milliliter/gram (ml/g, UCUM, "Milliliter/gram")
  MLG: 'MLG',

  // 1/centimeter (/cm, UCUM, "/Centimeter")
  '1CM': '1CM',

  // micromole/milliliter (umol/ml, UCUM, "Micromole/milliliter")
  UMOLML: 'UMOLML',

  // proportional to counts ({propcounts}, UCUM, "Proportional to counts")
  PROPCNTS: 'PROPCNTS',

  // proportional to counts/sec ({propcounts}/s, UCUM, "Proportional to counts per second")
  PROPCPS: 'PROPCPS',

  // milliliter/minute/milliliter (ml/min/ml, UCUM, "Milliliter/minute/milliliter")
  MLMINML: 'MLMINML',

  // milliliter/milliliter (ml/ml, UCUM, "Milliliter/milliliter")
  MLML: 'MLML',

  // grams/milliliter (g/ml, UCUM, "Gram/milliliter")
  GML: 'GML',

  // standard deviations
  STDDEV: 'STDEV',
};

export const FULL_MEMORY_USAGE = 10 * 1024 * 1024 * 1024;

export const PRODUCT_PORTAL_URL =
  'https://sirona.airfocus.com/share/forms/7ff4ed29b04271bd6f55e067d34251a6';

export const DEFAULT_ANNOTATION_TRANSFORMATIONS = {
  move: true,
  transform: true,
  delete: true,
};
